.root {
  width: 100%;
}

.item {
  border-bottom: none;
}

.control {
  background-color: #f9f9f9;
  transition: var(--transition);
}

.control:hover,
.control[data-active='true'] {
  background-color: #f5f5f5;
}

.label {
  color: #212529;
  font-size: var(--font);
  font-weight: 600;
  text-transform: uppercase;
}

.content {
  padding-top: var(--gap-md);
  padding-bottom: var(--gap-md);
}

.content a {
  color: #1675e0;
  font-weight: 500;
}

.content a:hover {
  text-decoration: underline;
}

.select {
  font-size: 14px;
  border-radius: 0;
  height: var(--input-height-md);
}

.select::placeholder {
  text-transform: capitalize;
}

.select:focus {
  border-color: var(--mantine-color-gray-4);
}

.select[data-expanded='true'] {
  border-color: var(--color-secondary);
}

.select__label {
  text-transform: capitalize;
  color: #131523;
  font-weight: 400;
  font-size: var(--font-xsm);
}

.select__icon {
  fill: var(--mantine-color-gray-4);
  cursor: pointer;
}

.select__icon svg {
  width: 24px;
}

.select[data-expanded='true'] + .select__icon,
.select__icon:hover {
  fill: var(--color-secondary);
}
