.tooltip {
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 1px;
}

.social-media {
  display: grid;
  place-items: center;
  transition: var(--transition);
}

.social-media svg {
  fill: var(--color-primary);
}

.social-media.circle {
  display: grid;
  place-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.social-media.circle svg {
  fill: #fff;
}

.social-media.circle.primary {
  background-color: var(--color-text-primary);
}

.social-media.circle.secondary {
  background-color: #bcbcbc;
}

.social-media.circle.secondary:hover {
  background-color: var(--color-text-primary);
}

@media (max-width: $breakpoint-3xl) {
  .social-media {
    transform: scale(0.9);
  }
}

@media print {
  .social-media {
    background-color: transparent !important;
  }

  .social-media svg {
    fill: var(--color-text-primary) !important;
  }
}
