.views.primary svg {
  fill: var(--color-primary);
}

.views.secondary svg {
  fill: #8c8c8c;
}

.views p {
  color: #666;
  font-size: var(--font-xsm);
}
