.box {
  cursor: pointer;
  border: 1px solid var(--color-border);
  transition: var(--transition);
}

.box.borderless {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.box.borderless .box__title {
  font-weight: 600;
}

.box:hover {
  border-color: var(--color-primary);
  color: var(--color-text-primary);
}

.box__title,
.box__day {
  font-size: var(--font-sm);
}

.box__date,
.box__day {
  color: #004094;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
}

.box__date {
  font-size: var(--font-xlg);
}
