.carousel {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
}

.loading {
  height: 160px;
}

.carousel__item {
  position: relative;
  height: 160px;
  outline: none;
}

.carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel__nextBtn,
.carousel__prevBtn {
  position: absolute;
  top: calc(50% - 5px);
  transform: translateY(-50%);
  cursor: pointer;
  width: 60px;
  height: 60px;
  padding-right: 5px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.05);
}

.carousel__nextBtn:hover,
.carousel__prevBtn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transition: var(--transition);
}

.carousel__nextBtn svg,
.carousel__prevBtn svg {
  width: 100%;
  height: 100%;
}

.carousel__nextBtn svg path,
.carousel__prevBtn svg path {
  stroke: var(--color-primary);
}

.carousel__nextBtn {
  transform: translateY(-50%) rotate(180deg);
  right: 0;
}

.carousel__prevBtn {
  left: 0;
}

@media (max-width: $breakpoint-3xl) {
  .loading {
    height: 140px;
  }

  .carousel__item {
    height: 140px;
  }
}

@media (max-width: $breakpoint-3xl) {
  .carousel__item img {
    object-fit: contain;
  }
}

@media (max-width: $breakpoint-lg) {
  .banners {
    display: none;
  }
}
