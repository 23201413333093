.map {
  position: relative;
  overflow: hidden;
}

.map::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
  background-image: url('/img/map_pattern.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: -1;
}

.map svg path {
  fill: #c8c8c8;
  stroke: #fff;
  cursor: pointer;
  outline: none;
  transition: var(--transition);
}

.map svg path:hover {
  fill: #7b7f94;
}

.map svg path[aria-checked='true'] {
  fill: var(--color-text-primary);
}

.map span {
  position: absolute;
  top: 2rem;
  right: 5rem;
  color: var(--color-text-primary);
  font-size: var(--font-xsm);
  font-weight: 600;
  text-transform: uppercase;
}

/* region */

.region {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  border-top: 1px solid var(--color-text-primary);
  border-bottom: 1px solid var(--color-text-primary);
  padding: var(--gap-lg);
  overflow: hidden;
}

.region::after {
  content: '';
  position: absolute;
  bottom: -30%;
  right: -10%;
  width: 640px;
  height: 640px;
  background: url('/img/modal_pattern.png');
  z-index: 0;
}

.region__title,
.region__name {
  color: var(--color-text-primary);
  text-transform: uppercase;
}

.region__title {
  font-size: 24px;
  font-weight: 700;
}

.region__name {
  font-size: 18px;
  font-weight: 600;
}

.region__info h3 {
  color: var(--color-text-primary);
  font-size: 26px;
  font-weight: 600;
}

.region__info ul {
  padding-top: var(--gap);
}

.region__info ul li {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: var(--gap-md);
}

.region__info ul li h4,
.region__info ul li a {
  font-size: 20px;
  padding-top: var(--gap-sm);
  padding-bottom: var(--gap-sm);
}

.region__info ul li h4:first-child {
  color: var(--color-text-primary);
  border-right: 1px solid var(--color-text-primary);
}

.region__info ul li a {
  color: var(--color-text-primary);
}

.region__info ul li a:hover,
.region__email:hover {
  text-decoration: underline;
}

.region__email {
  display: inline-block;
  margin-top: var(--gap);
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text-primary);
}

@media (max-width: $breakpoint-md) {
  .map span {
    max-width: 200px;
    text-align: right;
    top: 0;
    right: 0;
    text-transform: none;
  }

  .region__info ul li h4:first-child {
    border-right: none;
  }

  .region__info ul li {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .region__info h3 {
    font-size: 20px;
  }
}
