.shadow {
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.05);
}

.withRightMenu {
  position: relative;
  width: 100%;
  min-height: 10rem;
  display: grid;
  grid-template-columns: minmax(31.25rem, 10fr) 3fr;
  gap: var(--gap-md);
}

@media (max-width: $breakpoint-xl) {
  .withRightMenu {
    grid-template-columns: 100%;
  }
}
