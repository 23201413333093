.body {
  padding-top: var(--gap);
  padding-bottom: var(--gap);
  font-size: var(--font-md);
  color: #000;
  line-height: 1.3;
  text-align: justify;
}

.body p + p {
  padding-top: var(--gap-sm);
}

.body:has(ol) {
  overflow-x: visible;
}

.body:has(table) {
  overflow-x: auto;
}

.body ul {
  list-style: disc;
  padding-left: 2rem;
}

.body ol {
  list-style: decimal !important;
  padding-left: 2rem;
}

.body img {
  display: block;
  max-width: 100%;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.body iframe {
  margin: 0 auto;
}

.body a {
  font-weight: 600;
  text-decoration: underline;
  color: #1675e0;
  word-wrap: break-word;
}

.body a:hover {
  text-decoration: none;
}

.body table {
  width: 100%;
}

.body table,
.body table tr,
.body table td,
.body table th {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
}

.body table th,
.body table td {
  padding: var(--gap);
}

.body table tr:hover {
  background-color: #ececec;
}

.body table a img {
  pointer-events: none; /* disabled zoom image */
}

@media (max-width: $breakpoint-lg) {
  .body img {
    margin-top: var(--gap);
    height: 280px !important;
    object-fit: contain;
  }
}
