.marquee {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  font-size: var(--font-sm);
  font-weight: 600;
  color: #ad0000;
  animation: scroll 35s infinite linear;
}

@media (max-width: 1020px) {
  .marquee p {
    animation-duration: 25s;
  }
}

@media (max-width: $breakpoint-md) {
  .marquee p {
    animation-duration: 15s;
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}
