.guide {
  border: none;
}

.modal {
  position: relative;
  overflow: hidden;
}

.modal::after {
  content: '';
  position: absolute;
  top: -30%;
  right: -5%;
  width: 640px;
  height: 640px;
  background: url('/img/modal_pattern.png');
}

.modal__header {
  background-color: transparent;
}

.modal__body {
  position: relative;
  z-index: 2;
}

.title {
  position: relative;
  width: max-content;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
}

.title span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 100%;
  max-width: 150px;
  height: 20px;
}

.left__line {
  right: calc(100% + var(--gap));
}

.left__line::before,
.left__line::after,
.right__line::before,
.right__line::after {
  position: absolute;
  content: '';
  background-color: #043b87;
  height: 1px;
}

.left__line::before,
.left__line::after {
  right: 0;
}

.right__line::before,
.right__line::after {
  left: 0;
}

.left__line::before,
.right__line::before {
  width: 75%;
  top: 6px;
}

.left__line::after,
.right__line::after {
  width: 100%;
  bottom: 6px;
}

.right__line {
  left: calc(100% + var(--gap));
}

.close__btn {
  width: 32px;
  height: 32px;
  margin-inline-start: 0;
}

.close__btn svg {
  fill: var(--color-secondary);
}

.close__btn:hover {
  background-color: transparent;
}

@media (max-width: $breakpoint-xl) {
  .modal::after {
    top: 0%;
  }

  .title {
    max-width: 20rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .modal {
    overflow: auto;
  }

  .modal::after {
    display: none;
  }

  .modal__header {
    background-color: #fff;
  }

  .title {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }

  .title span {
    display: none;
  }
}
