.table h3,
.table a {
  font-size: 16px;
}

.table a {
  color: var(--color-text-primary);
}

.table a:hover {
  text-decoration: underline;
}

.tr .td:first-child {
  font-weight: 600;
  /* max-width: 180px; */
}

.td__map {
  vertical-align: top;
}

@media (max-width: $breakpoint-lg) {
  .tr {
    display: flex;
    flex-direction: column;
  }
}

@media print {
  .table,
  .th,
  .td {
    border: 1px solid var(--mantine-color-gray-3);
    border-collapse: collapse;
  }

  .tbody .tr:nth-child(odd) {
    background-color: var(--mantine-color-gray-1);
  }
}
