.activities {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  row-gap: var(--gap);
  column-gap: var(--gap-md);
}

@media (max-width: $breakpoint-md) {
  .activities {
    grid-template-columns: 100%;
  }
}
