.box {
  cursor: pointer;
  border: 1px solid var(--color-border);
  transition: var(--transition);
}

.box:hover {
  border-color: var(--color-primary);
  color: var(--color-text-primary);
}

.box--loading {
  pointer-events: none;
}

.box__icon {
  flex-shrink: 0;
  fill: var(--color-secondary);
  width: 32px;
  height: 32px;
  transition: var(--transition);
  opacity: 0.8;
}

.box:hover .box__icon {
  fill: var(--color-primary);
  opacity: 1;
}

.line {
  min-width: 3px;
  width: 3px;
  height: 100%;
  background-color: var(--color-primary);
  border-radius: 5px;
}

.box__img img {
  object-fit: contain;
}

@media (max-width: $breakpoint-sm) {
  .box__img {
    display: none;
  }

  .box__icon {
    width: 24px;
    height: 24px;
  }
}
