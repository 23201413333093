.links {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-lg);
}

.link {
  width: 100%;
  max-width: 400px;
  justify-self: center;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.link:hover {
  text-decoration: underline;
}

.link img {
  object-fit: contain;
}

.link h5 {
  font-size: var(--font-xsm);
  font-weight: 600;
  color: var(--color-text-primary);
}
