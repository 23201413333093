.container {
  display: flex;
  gap: var(--gap);
  justify-content: space-between;
  padding: 0 var(--gap-xl);
}

.column {
  max-width: max-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

@media screen and (max-width: 768px) {
  .container {
    justify-content: center;
  }
}
