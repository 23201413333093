.header {
  width: 100%;
  background-color: #fff;
  position: sticky;
  top: 0;
  margin-top: 1px; /* 1px = navbar's box shadow */
  z-index: 1000;
}

.title {
  font-size: var(--font-md);
  text-transform: uppercase;
  font-weight: 500;
  max-width: 30rem;
  line-height: 1.3;
}

.search svg {
  transform: scale(0.9);
  fill: var(--color-primary);
}

.phone {
  position: relative;
}

.phone::before,
.phone::after {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: var(--color-primary);
  opacity: 0.5;
}

.phone::before {
  bottom: calc(100% + 5px);
}

.phone::after {
  top: calc(100% + 5px);
}

.phone svg {
  width: 24px;
  fill: var(--color-primary);
}

.helpline__title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.helpline__number {
  font-size: 24px;
  font-weight: 500;
}

.helpline__contact {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.helpline__number:hover,
.helpline__contact:hover {
  text-decoration: underline;
}

/* search modal */

.modal__content {
  border-radius: var(--radius-input);
}

.modal__body {
  padding: 0;
}

/* header navigation */

.header__navigation {
  position: relative;
  width: 100%;
  background-color: #f0f0f0;
  display: grid;
  grid-template-columns: 1fr 60px;
}

.menu__item {
  font-size: var(--font-sm);
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  padding: var(--gap);
  transition: var(--transition);
}

.menu__item:hover,
.menu__item[data-expanded='true'],
.menu__item[data-active='true'] {
  background-color: var(--color-primary);
  color: #f0f0f0;
}

.menu__item::after {
  display: inline-block;
  content: '';
  margin-left: 0.5rem;
  vertical-align: 0.2rem;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown {
  min-width: 200px;
  max-width: 320px;
  max-height: 60vh;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  overflow-y: auto;
}

.dropdown::-webkit-scrollbar {
  width: 5px;
}

.dropdown::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.dropdown::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 1px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.item {
  background: none;
  font-size: var(--font-sm);
  color: #fff;
  padding: 0.8rem var(--gap);
  border-radius: 0;
  transition: var(--transition);
}

.item:hover,
.item[data-active='true'],
.item:focus {
  background: rgba(255, 255, 255, 0.5);
}

.item[data-active='true'] {
  pointer-events: none;
}

/* sitemap */

.sitemap__btn {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-left: 1px solid #d9d9d9;
  transition: var(--transition);
}

.sitemap__btn.mobile {
  display: none;
  height: 60px;
  border-left: none;
}

.sitemap__btn:hover,
.sitemap__btn[data-expanded='true'] {
  background-color: #003a87;
  border-color: #003a87;
}

.sitemap__btn.mobile:hover,
.sitemap__btn.mobile[data-expanded='true'] {
  background-color: transparent;
  border-color: var(--color-primary);
}

.sitemap__btn:hover .sitemap__burger,
.sitemap__btn:hover .sitemap__burger::before,
.sitemap__btn:hover .sitemap__burger::after,
.sitemap__btn[data-expanded='true'] .sitemap__burger::before,
.sitemap__btn[data-expanded='true'] .sitemap__burger::after {
  background-color: #fff;
}

.sitemap__btn.mobile:hover .sitemap__burger,
.sitemap__btn.mobile:hover .sitemap__burger::before,
.sitemap__btn.mobile:hover .sitemap__burger::after,
.sitemap__btn.mobile .sitemap__burger,
.sitemap__btn.mobile .sitemap__burger::before,
.sitemap__btn.mobile .sitemap__burger::after,
.sitemap__btn.mobile[data-expanded='true'] .sitemap__burger::before,
.sitemap__btn.mobile[data-expanded='true'] .sitemap__burger::after {
  background-color: var(--color-primary);
}

.sitemap__btn[data-expanded='true']:hover .sitemap__burger,
.sitemap__btn.mobile[data-expanded='true'] .sitemap__burger {
  background-color: transparent;
}

.header__navigation:has(.sitemap__btn[data-expanded='true']) > .header__menu {
  pointer-events: none;
}

.sitemap__dropdown {
  width: 100% !important;
  left: 0 !important;
  border: none;
  padding-top: var(--gap-md);
  padding-bottom: var(--gap-md);
  height: max-content;
  max-height: 60vh;
  overflow-y: auto;
  border-radius: 0;
}

.sitemap__item h3 {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.sitemap__item ul {
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  gap: var(--gap-sm);
  padding-top: var(--gap);
}

.sitemap__item ul li {
  position: relative;
  padding-left: 15px;
  max-width: 320px;
}

.sitemap__item ul li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-left: 7px solid #fff;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
}

.sitemap__item ul a {
  color: #eee;
  font-size: 16px;
}

.sitemap__item ul a:hover {
  color: #fff;
  text-decoration: underline;
}

.sitemap__mobile__target {
  position: absolute;
  top: 0;
  right: 16px;
  width: 34px;
  height: 100%;
  display: none;
  place-items: center;
}

@media (max-width: $breakpoint-3xl) {
  .title {
    font-size: var(--font);
  }

  .menu__item {
    font-size: var(--font-xsm);
    padding: 0.7rem var(--gap);
  }

  .item {
    font-size: var(--font-xsm);
    color: #fff;
    padding: var(--gap-sm) var(--gap);
  }

  .phone svg {
    width: 18px;
  }

  .helpline__title {
    text-transform: none;
    width: min-content;
  }

  .helpline__number {
    font-size: 18px;
  }

  .helpline__contact {
    font-size: 16px;
  }

  /* sitemap */

  .sitemap__item h5 {
    text-transform: none;
  }

  .sitemap__item ul a {
    font-size: 14px;
  }
}

@media (max-width: $breakpoint-xl) {
  .title {
    text-transform: capitalize;
  }

  .helpline__contact {
    display: none;
  }
}

@media (max-width: $breakpoint-lg) {
  .header__navigation {
    display: none;
  }

  .sitemap__btn.mobile {
    display: grid;
  }

  .sitemap__mobile__target {
    display: grid;
  }

  .sitemap__dropdown {
    max-height: 70vh;
  }

  .helpline__title {
    display: none;
  }

  .phone::before,
  .phone::after {
    display: none;
  }
}
