.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  font-size: calc(var(--font-xsm) - 2px);
  font-weight: bold;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  transition: 0.1s linear;
}

.link::after {
  position: absolute;
  content: '';
  top: 100%;
  width: 0;
  height: 2px;
  background-color: #000;
  transition: 0.1s linear;
}

.link:hover::after {
  width: 60%;
}

.link svg {
  stroke: rgba(0, 0, 0, 0.6);
  transition: 0.1s linear;
}

.link:hover {
  color: #000;
  opacity: 1;
}

.link:hover svg {
  stroke: #000;
  transform: translateX(2px);
}
