.root {
  width: 100%;
}

.item {
  border-bottom: none;
}

.control {
  background-color: #f9f9f9;
  transition: var(--transition);
}

.control:hover,
.control[data-active='true'] {
  background-color: #f5f5f5;
}

.label {
  color: #212529;
  font-size: var(--font);
  font-weight: 600;
  text-transform: uppercase;
}

.content {
  padding-top: var(--gap-md);
  padding-bottom: var(--gap-md);
}

.btn__root {
  background-color: #e6e6e6;
  color: #707070;
  font-size: var(--font-md);
  font-weight: 500;
  border-radius: 0;
  transition: var(--transition);
}

.btn__root:hover {
  background-color: #ededed;
  color: #707070;
}

.btn__section {
  width: 20px;
  transition: transform 0.1s linear;
}

.btn__section.active {
  transform: rotate(180deg);
}

.inner_guides {
  grid-template-columns: 1fr 2fr;
}
