.carousel {
  position: relative;
  padding-left: 48px;
  padding-right: 48px;
}

.carousel > div {
  border-left: 1px solid #004094;
  border-right: 1px solid #004094;
}

.carousel > div > div {
  display: flex;
  align-items: center;
}

.carousel__item {
  padding-left: var(--gap);
  padding-right: var(--gap);
}

.carousel__item:hover {
  text-decoration: underline;
}

.carousel__item img {
  max-width: 80px;
  object-fit: cover;
}

.carousel__item a {
  font-size: var(--font-sm);
  font-weight: 500;
  color: #004094;
  line-height: 1.4;
}

.carousel__nextBtn,
.carousel__prevBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding-right: 5px;
}

.carousel__nextBtn svg,
.carousel__prevBtn svg {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  transition: var(--transition);
}

.carousel__nextBtn:hover svg,
.carousel__prevBtn:hover svg {
  opacity: 1;
}

.carousel__nextBtn svg path,
.carousel__prevBtn svg path {
  stroke: var(--color-primary);
}

.carousel__nextBtn {
  transform: translateY(-50%) rotate(180deg);
  right: 0;
}

.carousel__prevBtn {
  left: 0;
}
