.marquee {
  display: block;
  width: 100%;
  background-color: rgba(1, 61, 140, 0.9);
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
}

.marquee > div {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 50px;
  padding-left: 100%;
  -webkit-animation: scroll 30s infinite linear;
  -moz-animation: scroll 30s infinite linear;
  animation: scroll 30s infinite linear;
}

.marquee:hover div {
  animation-play-state: paused;
}

.marquee p,
.marquee a {
  display: inline-block;
  margin: 0;
  color: #fff;
  font-size: var(--font-sm);
  font-weight: 500;
}

.marquee a:hover {
  text-decoration: underline;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@-moz-keyframes scroll {
  0% {
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -moz-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
