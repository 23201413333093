.breadcrumb {
  color: #666;
  font-size: calc(var(--font-xsm) - 2px);
  pointer-events: none;
  white-space: normal;
  line-height: 1.4;
}

.breadcrumb.insideLink {
  font-size: calc(var(--font-xsm) - 4px);
  text-decoration: none;
}

.breadcrumb.active.insideLink {
  font-weight: normal;
  color: #666;
}

.breadcrumb.active.insideLink:hover {
  text-decoration: none;
}

.breadcrumb.active.insideLink + div svg path {
  stroke: #666;
}

.breadcrumb.active {
  font-weight: 700;
  color: var(--color-text-primary);
  pointer-events: all;
}

.breadcrumb.active:hover {
  text-decoration: underline;
}

.breadcrumb.active + div svg path {
  stroke: var(--color-text-primary);
}

.icon {
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
}

.icon.insideLink {
  width: 18px;
  height: 18px;
}

.icon path {
  stroke: #666;
}
