.three-stack.flexible {
  grid-template-columns: repeat(auto-fit, minmax(min(21.875rem, 100%), 1fr));
}

.three-stack > div[data-bordered='1'] {
  border-right: 1px solid var(--color-border);
  padding-right: var(--gap);
}

.three-stack > div[data-bordered='2'] {
  padding-left: var(--gap);
  padding-right: var(--gap);
}

.three-stack > div[data-bordered='3'] {
  border-left: 1px solid var(--color-border);
  padding-left: var(--gap);
}

@media screen and (max-width: 1400px) {
  .three-stack > div[data-bordered='2'] {
    padding-right: 0;
  }

  .three-stack > div[data-bordered='3'] {
    border-left: none;
    padding-left: 0;
  }
}

@media screen and (max-width: 992px) {
  .three-stack > div[data-bordered='1'] {
    border-right: none;
    padding-right: 0;
  }

  .three-stack > div[data-bordered='2'] {
    padding-left: 0;
  }
}

@media (max-width: $breakpoint-sm) {
  .three-stack.flexible {
    grid-template-columns: 100%;
  }
}
